import React, { useEffect, useState } from "react";
import {
  ILastPurchaseResponse,
  IPurchasedTicket,
  IShoppingBasketData,
} from "../helpers/models/txticket_api.model";
import { IBuyerDetailsDto, IUserDto } from "../helpers/models/auth.model";
import ApiSessionInstance from "../helpers/session_api";

export interface GlobalContextType {
  user: undefined | IUserDto;
  updateUser: (data: IUserDto) => void;
  resetUser: () => void;
  shoppingCartBasket: IShoppingBasketData[];
  totalShoppingCartBasketPrice: number;
  showPay: boolean;
  setShowPay: React.Dispatch<React.SetStateAction<boolean>>;
  ticketList: IPurchasedTicket[];
  // this is up for a chan
  setTicketList: (
    ticketList: ILastPurchaseResponse[],
    isAuthenticated?: boolean
  ) => void;
  purchasedTickets: IPurchasedTicket[];
  setPurchasedTickets: (ticketList: IPurchasedTicket[]) => void;
  lastPurchaseTicketList: ILastPurchaseResponse | undefined;
  setLastPurchaseTicketList: (ticketList: ILastPurchaseResponse) => void;
  removeFromShoppingCartBasket: (biunique: number) => void;
  updateShoppingCartBasket: (shoppingData: IShoppingBasketData[]) => void;
  addToShoppingCartBasket: (shoppingData: IShoppingBasketData) => void;
  buyerDetails: undefined | IBuyerDetailsDto;
  updateBuyerDetails: (data: IBuyerDetailsDto) => void;
  resetBuyerDetails: () => void;
  setTotalShoppingCartBasketPrice: React.Dispatch<React.SetStateAction<number>>;
}

export const GlobalContext = React.createContext<GlobalContextType | null>(
  null
);

const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUserDto>();
  const [buyerDetails, setBuyerDetails] = useState<IBuyerDetailsDto>();
  const [shoppingCartBasket, setShoppingCartBasket] = useState<
    IShoppingBasketData[]
  >([]);
  const [ticketList, setStateTicketList] = useState<IPurchasedTicket[]>([]);
  const [purchasedTickets, setStatePurchasedTicket] = useState<
    IPurchasedTicket[]
  >([]);
  const [lastPurchaseTicketList, setStateLastPurchaseTicketList] =
    useState<ILastPurchaseResponse>();
  const [totalShoppingCartBasketPrice, setTotalShoppingCartBasketPrice] =
    useState<number>(0);
  const [showPay, setShowPay] = useState<boolean>(false);

  const updateShoppingCartBasket = (shoppingData: IShoppingBasketData[]) => {
    setShoppingCartBasket((prev) => [...shoppingData].reverse());
  };

  const addToShoppingCartBasket = (shoppingData: IShoppingBasketData) => {
    setShoppingCartBasket((prev) => [...prev, shoppingData]);
  };

  const setTicketList = (
    ticketList: ILastPurchaseResponse[],
    isAuthenticated?: boolean
  ) => {
    // check ticket list is empty
    if (!ticketList.length || !Object.keys(ticketList[0]).length) return;

    if (isAuthenticated) {
      // over-write cache storage. expected an api call to get all purchased tickets
      ApiSessionInstance.setTicketList(ticketList);
      setStateTicketList(ticketList.map((tkr) => tkr.ticketlist).flat());
      return;
    }

    // update cache storage
    const storedTicketList = ApiSessionInstance.getTicketList();
    const isAlreadyStored = (storedTicketList || []).find(
      (tk) => tk.purchaseid === ticketList[0].purchaseid
    );
    if (!isAlreadyStored) {
      const newTicketList = [...(storedTicketList || []), ...ticketList];
      ApiSessionInstance.setTicketList(newTicketList);
      setStateTicketList(newTicketList.map((tkr) => tkr.ticketlist).flat());
    }
  };

  const setPurchasedTickets = (ticketList: IPurchasedTicket[]) => { 
    // debugger
    ApiSessionInstance.setPurchasedTickets(ticketList);
    setStatePurchasedTicket(ticketList);
  };

  const setLastPurchaseTicketList = (ticketList: ILastPurchaseResponse) => {
    setStateLastPurchaseTicketList(ticketList);
  };

  const updateBuyerDetails = (data: IBuyerDetailsDto) => {
    setBuyerDetails((prev) => ({ ...prev, ...data }));
    ApiSessionInstance.setBuyerDetails({ ...buyerDetails, ...data });
  };

  const resetBuyerDetails = () => {
    setBuyerDetails(undefined);
  };

  const updateUser = (data: IUserDto) => {
    setUser((prev) => ({ ...prev, ...data }));
    ApiSessionInstance.setUser({ ...user, ...data });
  };

  const resetUser = () => {
    setUser(undefined);
  };

  const removeFromShoppingCartBasket = (biunique: number) => {
    const shoppingData = [...shoppingCartBasket];
    const shoppingCart = shoppingData.filter(
      (shoppingDatum: IShoppingBasketData) =>
        shoppingDatum.biunique !== biunique
    );
    setShoppingCartBasket([...shoppingCart]);
  };

  useEffect(() => {
    const apiBuyerDetails = ApiSessionInstance.getBuyerDetails();
    if (apiBuyerDetails) setBuyerDetails(apiBuyerDetails);
    const apiTicketList = ApiSessionInstance.getTicketList();
    if (apiTicketList)
      setStateTicketList(apiTicketList.map((tkr) => tkr.ticketlist).flat());
    const apiPurchasedTickets = ApiSessionInstance.getPurchasedTickets();
    if (apiPurchasedTickets) setStatePurchasedTicket(apiPurchasedTickets);
    const apiUser = ApiSessionInstance.getUser();
    if (apiUser) setUser(apiUser);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        shoppingCartBasket,
        addToShoppingCartBasket,
        removeFromShoppingCartBasket,
        updateShoppingCartBasket,
        showPay,
        setShowPay,
        totalShoppingCartBasketPrice,
        setTotalShoppingCartBasketPrice,
        ticketList,
        setTicketList,
        purchasedTickets,
        setPurchasedTickets,
        lastPurchaseTicketList,
        setLastPurchaseTicketList,
        buyerDetails,
        resetBuyerDetails,
        updateBuyerDetails,
        user,
        updateUser,
        resetUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
