import { useMediaQuery } from "react-responsive";
import { IPurchasedTicket } from "../../../../helpers/models/txticket_api.model";
import moment from "moment";

interface TicketItemProps {
  purchasedTicket: IPurchasedTicket;
}

const TicketItem = ({ purchasedTicket }: TicketItemProps) => {
  console.log("your puchased ticket is =>", purchasedTicket);
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  return (
    <tr className="mb-5" style={{ fontWeight: "500" }}>
      <td
        className="bg-transparent text-white border-0 pt-0 text-light-emphasis pb-4"
        style={{ width: "46px" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p
            style={{
              marginBottom: "55px",
              fontWeight: "500",
              // paddingLeft: "-45px",
            }}
            className="p-0 fs-16 text-dark"
          >
            1
          </p>
        </div>
      </td>
      <td
        className="bg-transparent text-white border-0 fs-13 td-wrap text-start"
        style={{ fontWeight: "500", marginBottom: "-60px" }}
      >
        <p className={`${isMediumScreen ? "fs-16" : "fs-13"} mb-0 text-dark`} >
          {/* {selectedStations.from} -&gt; {selectedStations.to} */}
          {purchasedTicket.stid1_name} -&gt; {purchasedTicket.stid2_name}
        </p>
        <p className={`${isMediumScreen ? "fs-13" : "fs-11"} mb-1 text-dark`}>
          <span>
            {moment(new Date(purchasedTicket.inception)).format("dddd")},{" "}
            {moment(new Date(purchasedTicket.inception)).format("D")}
            <sup>
              {moment(new Date(purchasedTicket.inception))
                .format("Do")
                .replace(/\d+/g, "")}
            </sup>{" "}
            {moment(new Date(purchasedTicket.inception)).format("MMMM")}{" "}
            {moment(new Date(purchasedTicket.inception)).format("YYYY")}
          </span>
          {", "} {isMediumScreen ? null : <br/> }
          {purchasedTicket.rvlist[0].tod1}
          {" - "}
          {purchasedTicket.rvlist[0].tod2} {", "}
          Train No. 264,
          <strong className="text-dark"> Free Seating.</strong>
        </p>
        <p className="text-dark" style={{ marginTop: isMediumScreen ?  "-5px" : "-7px", marginBottom : isMediumScreen ? "" : "10px" }}>
          <strong>
            Naira{" "}
            {(purchasedTicket.centprice / 100 || 0).toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 0,
              },
            )}
          </strong>
        </p>
      </td>
    </tr>
  );
};

export default TicketItem;
