import React from "react";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const displayContent = !isMediumScreen
    ? "footer-display-mb"
    : "footer-display";
  return (
    <footer className={`${displayContent}  d-md-block `}>
      The Nigerian Railway Corporation 2024
    </footer>
  );
}
