import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react/jsx-runtime";

interface BuyingProcessedLoaderProps {
  children?: React.ReactNode; // For reusability on buy page
  hideOnSmallScreen?: boolean;
}

const BuyingProcessedLoader = (props: BuyingProcessedLoaderProps) => {
  const marginBot = props.hideOnSmallScreen ? "py-md-5" : "mb-4";
  const isSmallScreen = useMediaQuery({
    query:"(max-width:330px)"
  })
const adjustStyle= isSmallScreen? "fs-20" : "fs-36"
  return (
    <Fragment>
      {/* <Col md={9} className="fs-14 mt-3 mx-auto">
        <p
          className="text-white-50 text-center d-flex justify-content-between mx-auto"
          style={{ maxWidth: "300px" }}
        >
          <span className="text-white">Booking</span>
          <span className="text-white">&gt;</span>
          <span className="text-white">Shopping Cart</span>
          <span className="text-white">&gt;</span>
          <span className="text-white"> Buy </span>
          <span className="text-white">&gt;</span>
          <span> Ticket </span>
        </p>
      </Col> */}

      <Col
        style={{
          background: props.hideOnSmallScreen ? "transparent" : "transparent",
          // paddingBottom: props.hideOnSmallScreen? "30px" : "70px",
          height: props.hideOnSmallScreen ? "585px" : "585px",
          marginTop: props.hideOnSmallScreen ? "60px" : "60px",
        }}
        md={9}
        lg={12}
        xl={9}
        className={`m-auto ${
          props.hideOnSmallScreen ? "" : "border border-primary mb-2 "
        } rounded-4 p-5 px-md-3 `}
      >
        <div className="d-md-none d-block">
          <h1
            className={`${adjustStyle}text-capitalize text-white text-center pb-3`}
            style={{ paddingTop: props.hideOnSmallScreen ? "" : "40px" }}
          >
            Please <span className="text-primary">Wait...</span>
          </h1>
        </div>
        <div className="d-md-block d-none">
          <h1 className="text-capitalize text-white text-center pb-3">
            PLEASE <span className="text-primary ">WAIT...</span>
          </h1>
        </div>
        <div
          className="rounded-circle border border-3 border-primary d-flex justify-content-center align-items-center mb-5"
          style={{
            width: props.hideOnSmallScreen ? isSmallScreen?"290px" : "290px" : "390px",
            height: props.hideOnSmallScreen ? "290px" : "390px",
            marginTop: "30px",
            marginLeft: props.hideOnSmallScreen ? isSmallScreen?"-50px" : "-22px" : "25%",
          }}
        >
          <p
            className={`text-primary text-center  ${
              props.hideOnSmallScreen ? "fs-18" : "fs-23"
            }`}
          >
            … while we are processing <br /> your order…
          </p>
        </div>
      </Col>
    </Fragment>
  );
};

export default BuyingProcessedLoader;
