import "./App.css";
//import Scss
import "./assets/scss/themes.scss";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import GlobalProvider from "./context/GlobalContext";
import { Fragment } from "react";
// routes
import Route from "./Routes/Index";

import "react-toastify/dist/ReactToastify.css";
import BookingProvider from "./context/BookingContext";

function App() {
  return (
    <Fragment>
      <Helmet>
        <script
          src="https://pay-service.icadpay.com/host/new-inline-stage-pay.js"
          async
        ></script>
      </Helmet>
      <GlobalProvider>
        <BookingProvider>
          <BrowserRouter>
            <Route />
          </BrowserRouter>
        </BookingProvider>
      </GlobalProvider>
    </Fragment>
  );
}

export default App;
