import { Col } from "react-bootstrap";
import Orders from "./Orders/Orders";
import { Fragment } from "react";
import { IShoppingBasketData } from "../../helpers/models/txticket_api.model";
interface ShoppingCartProps {
  shoppingCartBasket: IShoppingBasketData[];
  children?: React.ReactNode; // For reusability on buy page
  title?: string;
}

const SmallScreenShoppingCart = ({
  shoppingCartBasket,
  children,
  title,
}: ShoppingCartProps) => {
 
  return (
    <Fragment>
      <Col
        style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
        md={12}
        sm={10}
        className="m-auto border border-primary rounded-4 p-2 text-center mb-md-2 d-none d-md-block"
      >
        <h1 className="text-capitalize text-primary text-center">
          {title || "SHOPPING CART"}
        </h1>
        <Orders
          title={title || "SHOPPING CART"}
          children={children}
          shoppingCartBasket={shoppingCartBasket}
        />
      </Col>

      <div className="d-md-none d-block" style={{ marginTop:"-15px"}}>
        <Orders
          title={title || "SHOPPING CART"}
          children={children}
          shoppingCartBasket={shoppingCartBasket}
        />
      </div>
    </Fragment>
  );
};

export default SmallScreenShoppingCart;
