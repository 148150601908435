import { Col } from "react-bootstrap";
import PurchaseWithoutRegisteringForm from "./Form/Form";
import { Fragment } from "react";

interface PurchaseWithoutRegisteringSmallScreenProps {
  children?: React.ReactNode; // For reusability on buy page
}

const PurchaseWithoutRegisteringSmallScreen = ({
  children,
}: PurchaseWithoutRegisteringSmallScreenProps) => {
  return (
    <Fragment>
      <Col
        style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
        md={10}
        className="m-auto border border-primary rounded-4 p-2 text-center mb-md-5 d-none d-md-block"
      >
        <h1 className="text-capitalize text-primary text-center">
          Shopping cart
        </h1>
        <PurchaseWithoutRegisteringForm children={children} />
      </Col>

      <div className="d-md-none d-block">
        <PurchaseWithoutRegisteringForm children={children} />
      </div>
    </Fragment>
  );
};

export default PurchaseWithoutRegisteringSmallScreen;
