import React, { useState } from "react";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import { Button, Col } from "react-bootstrap";
import GoButton from "../../component/GoButton/GoButton";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import ApiInstance from "../../helpers/txticket_api";
import { toast } from "react-toastify";
import { IStartPaymentResponse } from "../../helpers/models/txticket_api.model";
import ApiSessionInstance from "../../helpers/session_api";
import { IIcadPayPaymentResponse } from "../../helpers/models/payment_gateway.model";
// import DynamicStylerInstance, { modalStyle } from "../../helpers/dynamic_styler_helper";

interface IBuyProps {
  children?: React.ReactNode; // For reusability on buy page
}

declare global {
  interface Window {
    IcadPay: any;
  }
}

const paymentProviders = ["IcadPay"];

const Buy = (props: IBuyProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/buy" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  // const [state, setState] = useState<IStartPaymentResponse>();
  const [narration] = useState("NRC - ticket purchase");
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState("");

  const { buyerDetails, user } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;

  const getPaymentRefProviderName = () => {
    if (ApiSessionInstance.getStartPayment()) {
      return ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
    }
    return null;
  };

  const onPaymentSuccess = (response: IIcadPayPaymentResponse) => {
    console.log("onPaymentSuccess response", response);
    navigate("/buying-processed-loading");
    // const dt = getPaymentRefProviderName();
    // ApiSessionInstance.resetStartPayment();
    // ApiSessionInstance.clearAbruptlyCancelledPurchase();
    // ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    // ApiSessionInstance.onPaymentSuccessConfirmation({
    //   response: response.responseData,
    //   pgwname: dt ? dt.pgwname : selectedPaymentProvider,
    //   pmtref: response.responseData.merchantTransactionRef,
    //   pmtauth: response.responseData.transactionRef,
    // });

    // ApiInstance.confirm_payment(
    //   dt ? dt.pgwname : selectedPaymentProvider,
    //   response.responseData.merchantTransactionRef,
    //   response.responseData.transactionRef,
    //   response.responseData,
    //   () => {
    //     ApiSessionInstance.resetStartPayment();
    //     ApiSessionInstance.clearAbruptlyCancelledPurchase();
    //     ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    //     console.log("this is the confirmation of payment:");
    //     ApiInstance.clear_shopping_basket();
    //     // navigate("/buying-processed-loading");
    //   }
    // );
  };

  const onPaymentError = (response: any) => {
    ApiSessionInstance.clearAbruptlyCancelledPurchase();
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    console.log("onPaymentError response", response);
    toast.error("ticket purchase failed..");
    // if (state) {
    if (ApiSessionInstance.getStartPayment()) {
      // ApiInstance.cancel_payment(selectedPaymentProvider, state["pmtref"]);
      const dt = ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
      ApiInstance.cancel_payment(dt.pgwname, dt["pmtref"]);
      ApiSessionInstance.resetStartPayment();
    }
  };

  const onClosePayment = () => {
    // const pmtref = ApiInstance.get_last_pmtref();
    // console.log('onClosePayment pmtref', pmtref)
    toast.success("payment window closed");
    // if (state) {
    if (ApiSessionInstance.getStartPayment()) {
      // ApiInstance.cancel_payment(selectedPaymentProvider, state["pmtref"]);
      const dt = ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
      ApiInstance.cancel_payment(dt.pgwname, dt["pmtref"]);
      ApiSessionInstance.clearAbruptlyCancelledPurchase();
      ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
      ApiSessionInstance.resetStartPayment();
    }
    navigate("/shopping-cart");
    // DynamicStylerInstance.applyStyles(modalStyle)
    window.location.reload();
  };

  const onStartPurchase = () => {
    if (!buyerDetails) return;
    const totalShoppingCartBasketPrice =
      ApiInstance.get_shopping_basket_amount();
    const email = user ? user.email : buyerDetails.email;
    const firstName = user ? user.firstName : buyerDetails.firstName;
    const lastName = user ? user.lastName : buyerDetails.lastName;
    const telephone = user ? user.telephone : buyerDetails.telephone;
    ApiSessionInstance.clearAbruptlyCancelledPurchase();
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    ApiInstance.start_payment(
      selectedPaymentProvider,
      email,
      `${narration} by ${firstName} ${lastName}`,
      (data) => {
        ApiSessionInstance.clearAbruptlyCancelledPurchase();
        ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
        navigate("/buying", { state: { method: "buying", data } });
        // setState(data);
        ApiSessionInstance.onStartPayment(data);
        const payload = {
          // key: "live_NDNlMGM5ZjJlYzliZTBmYjNiMjgzOGFlNzkyZGQ4MTBjZDFmMjQ2YjYzZWRhMmNkYjJhOGMwYWNjZTA3MDJmNA", // this is not a demo key.
          // key: "test_MDE0OTY1Y2NjNDI0MjIyZjY1ZWYwOWQ1YzkyMmJjZTZkYzlhZDBiZDVkOTg5ZDBmZjllYTMyMzVjNTI4MmJmMQ", // this is a demo key.
          // key: 'test_YzA1MmNmYzE0OTc1Y2QyM2ViNWUwNTIxOGMzZjA2MjI5N2M4ZDU3YmY5ZDg1ZmU1OWIwNWE1NDU0YjkzYTZkOQ', // this is a demo key.
          key: "test_NjA0N2QzZjg1NjNjOGIwYTNmYzIwZjNmZTY1MGE0NDZmYjk1OTgxNWMzODdiOTFiYzVlNzM5YWM4MGU5NDM0Nw",
          email: buyerDetails.email, // customer email
          amount: totalShoppingCartBasketPrice / 100, // amount to be processed
          currency: "NGN", // currency
          first_name: firstName,
          last_name: lastName,
          phone_number: telephone, // customer's phone number (optional)
          customerId: email,
          ref: data["pmtref"],
          // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
          narration: `${narration} by ${firstName} ${lastName}`,
          isBill: true,
          service_bill: "",
          callback_url: "https://nrc-icad.txware.com/", // specified redirect URL (potional)
          // callback_url: '', // specified redirect URL (optional)
          callback: (response: any) => {
            // console.log('selectedPaymentProvider - callback', selectedPaymentProvider)
            // console.log('ApiSessionInstance - callback', ApiSessionInstance.getStartPayment())
            if (
              response.requestSuccessful === true &&
              response.responseCode === "00" &&
              response.responseData &&
              response.responseData.responseCode === "00"
            ) {
              const dt = getPaymentRefProviderName();
              ApiSessionInstance.resetStartPayment();
              ApiSessionInstance.clearAbruptlyCancelledPurchase();
              ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
              ApiSessionInstance.onPaymentSuccessConfirmation({
                response: response.responseData,
                pgwname: dt ? dt.pgwname : selectedPaymentProvider,
                pmtref: response.responseData.merchantTransactionRef,
                pmtauth: response.responseData.transactionRef,
              });
              // console.log('onPaymentSuccessConfirmation', response);
            }
            // console.log(response);
          },
          onSuccess: onPaymentSuccess,
          onError: onPaymentError,
          onClose: onClosePayment,
        };
        async function iCadPay() {
          if (window.IcadPay) {
            const handler = await window.IcadPay.setup(payload);
            ApiSessionInstance.onShowPaymentGateway();
            console.log(handler);
          }
        }
        iCadPay();
      },
    );
  };

  return (
    <ShoppingCart title="Buy" show={!!matches}>
      <Col md={6} className="m-auto px-2 px-md-3">
        <p
          style={{ fontWeight: 700 }}
          className="text-primary fs-14 text-capitalize text-center"
        >
          {selectedPaymentProvider ? "Your " : "Choose Your "}Payment Provider?
        </p>
        <div className="d-flex justify-content-center mb-3">
          {paymentProviders.map((pp) => (
            <Button
              key={pp}
              style={{ fontWeight: 700 }}
              variant="outline"
              className={`rounded-4 ${
                selectedPaymentProvider
                  ? "text-white border-white"
                  : "text-primary border-primary"
              }`}
              onClick={() => setSelectedPaymentProvider(pp.toLowerCase())}
            >
              {pp}
            </Button>
          ))}
        </div>
      </Col>
      <GoButton
        disabled={!selectedPaymentProvider}
        onClick={onStartPurchase}
        disabledNextButton
        // isLoading
        onClickNextButton={() => {}}
        onClickBackButton={() => navigate("/shopping-cart")}
        showGroupButtons
      />
    </ShoppingCart>
  );
};

export default Buy;
