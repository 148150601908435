import { Button, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Orders from "./Orders/Orders";
import React, { useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import ApiInstance from "../../helpers/txticket_api";
import SmallScreenShoppingCart from "./SmallScreen";
import ApiSessionInstance from "../../helpers/session_api";
import TextsComponent from "../../component/TextsComponent/TextsComponent";
import Layout from "../../Layout";

interface IShoppingCartProps {
  children?: React.ReactNode; // For reusability on buy page
  show?: boolean;
  onHide?: (() => void) | undefined;
  title?: string;
}

const ShoppingCart = (props: IShoppingCartProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const someRoutes = [{ path: "/shopping-cart" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isLargeScreenBelow = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const isSmallScreen = useMediaQuery({
    query:"(max-width:330px)"
  })

  const { updateShoppingCartBasket, shoppingCartBasket } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;

  useEffect(() => {
    if (!isMounted) {
      let shoppingBasket = ApiInstance.get_shopping_basket();
      if (shoppingBasket) updateShoppingCartBasket(shoppingBasket);
      setIsMounted(true);
      ApiSessionInstance.resetShowPaymentGateway();
      ApiSessionInstance.removePurchaseWithoutRegisteringMounted();
    }
  }, [isMounted, updateShoppingCartBasket]);

  useEffect(() => {
    ApiInstance.on_shopping_basket_changed = () => {
      let shoppingBasket = ApiInstance.get_shopping_basket();
      if (shoppingBasket) updateShoppingCartBasket(shoppingBasket);
    };
  }, [updateShoppingCartBasket]);

  if (!shoppingCartBasket.length) {
    return (
      <Layout>
        <TextsComponent
          show={props.show !== undefined ? props.show : !!matches}
          title="SHOPPING CART"
          isEmpty={!shoppingCartBasket.length}
          texts={[
            "There are currently no tickets in your shopping cart",
            "Please book a ticket or sign in to your account for more information",
          ]}
        />
      </Layout>
    );
  }

  const onHideHandler = () => {
    console.log("onHideHandler");
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    navigate("/");
  };

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="backgroundDropdown px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          onHide={props.children ? props.onHide : onHideHandler}
          show={
            (props.show !== undefined ? props.show : !!matches) &&
            !ApiSessionInstance.getCancelledPurchaseContinuationPrompt()
          }
          size="xl"
          centered
        >
          <h1 className="text-capitalize text-primary text-center" style={{ fontSize: isSmallScreen? "14px" : ""}}>
            {props.title || "SHOPPING CART"}
          </h1>
          <Button
            onClick={() => navigate("/")}
            variant="white"
            className="text-white position-absolute end-0 mx-3"
            style={{ marginTop: "-20px" }}
          >
            <i className="bi bi-x display-3" />
          </Button>
          <Orders {...props} shoppingCartBasket={shoppingCartBasket} />
        </Modal>
      ) : (
        <SmallScreenShoppingCart
          {...props}
          shoppingCartBasket={shoppingCartBasket}
        />
      )}
    </Layout>
  );
};

export default ShoppingCart;
