import { Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { IShoppingBasketData } from "../../../helpers/models/txticket_api.model";
import OrderItem from "./OrderItem/OrderItem";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

interface OrdersProps {
  shoppingCartBasket: IShoppingBasketData[];
}

const Order = ({ shoppingCartBasket }: OrdersProps) => {
  const [newShoppingCartBasket, setNewShoppingCartBasket] = useState<
    IShoppingBasketData[]
  >([]);

  const isMobileScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    if (shoppingCartBasket) {
      const initialCarts = shoppingCartBasket.map(
        (sc) =>
          sc.route.leg[0].lxdate +
          sc.route.stidarr +
          sc.route.stiddep +
          sc.route.leg[0].tod_dep,
      );
      const carts = new Set(initialCarts);
      const newState = Array.from(carts.values());
      const shoppingCarts: IShoppingBasketData[] = [];
      newState.map((st) => {
        const len = initialCarts.filter((it) => it === st).length;
        let price = 0;
        shoppingCartBasket.filter((sc) => {
          const key =
            // sc.route.leg[0].lxdate + sc.route.stidarr + sc.route.stiddep;
            sc.route.leg[0].lxdate +
            sc.route.stidarr +
            sc.route.stiddep +
            sc.route.leg[0].tod_dep;
          if (key === st) price += sc.centprice;
          return sc;
        });
        const cartIndex = initialCarts.findIndex((r: string) => r === st);
        const newCart = {
          ...shoppingCartBasket[cartIndex],
          count: len,
          centprice: price,
        };
        shoppingCarts.push(newCart);
        return st;
      });
      setNewShoppingCartBasket(shoppingCarts);
    }
  }, [shoppingCartBasket]);

  return (
    <SimpleBar
      autoHide={false}
      forceVisible={"y"}
      style={{
        maxHeight: isMobileScreen ? "180px" : window.innerHeight / 5 - 10,
        // overflowY: contentHeight === 180 ? 'scroll' : 'hidden'
      }}
      className=""
    >
      <Table
        className="align-middle table-nowrap mb-0 bg-transparent"
        style={{ marginTop: isMobileScreen ? "0px" : "" }}
      >
        <tbody className="text-white">
          {newShoppingCartBasket.map((eachShoppingCartBasket) => (
            <OrderItem
              key={eachShoppingCartBasket.biunique}
              shoppingCartBasket={eachShoppingCartBasket}
            />
          ))}
        </tbody>
      </Table>
    </SimpleBar>
  );
};

export default Order;
