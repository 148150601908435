import React, { useContext, useState } from "react";
import {
  ITicketOrder,
  ITicketPrice,
} from "../helpers/models/txticket_api.model";
import { IRouteConnection } from "../helpers/models/route.model";
import ApiInstance from "../helpers/txticket_api";
import moment from "moment";
import { toast } from "react-toastify";
import ApiSessionInstance from "../helpers/session_api";
import { GlobalContext, GlobalContextType } from "./GlobalContext";

export interface BookingContextType {
  getReservation: (count: number) => Promise<unknown>;
  stations: string[];
  setStations: React.Dispatch<React.SetStateAction<string[]>>;
  unRefinedStations: string[];
  selectedStations: {
    from: string;
    to: string;
  };
  selectedDate: string;
  handleDateSelection: (date: string) => void;
  handleStationSelection: (selected: { [key: string]: string }) => void;
  handleTripDetailSubmission: (event: any) => void;
  isLoadingRoutingDetails: boolean;
  isLoadingPricingDetails: boolean;
  tickets: IRouteConnection[];
  ticket: IRouteConnection | undefined;
  ticketPrice: ITicketPrice | undefined;
  ticketPrices:
    | {
        [key: string]: ITicketPrice[];
      }
    | undefined;
  handleTicketPriceSelection: (selected: ITicketPrice) => void;
  handleTicketSelection: (selected: IRouteConnection) => void;
  passengers: number;
  handleSetPassenger: (numb: number) => void;
  setTicketsOrder: React.Dispatch<React.SetStateAction<ITicketOrder[]>>;
  hasConfirmedBooking: boolean;
  handleBookingConfirmation: (isConfirmed: boolean) => void;
  isBooking: boolean;
  resetTripSelectionBooking: () => void;
  isSubmittingBookingDetails: boolean;
  setIsBooking: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTripSelection: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingRoutingDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingPricingDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketPrices: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: ITicketPrice[];
        }
      | undefined
    >
  >;
  ticketsOrder: ITicketOrder[];
  setIsSubmittingBookingDetails: React.Dispatch<React.SetStateAction<boolean>>;
  addTicketOrder: (ticketOrder: ITicketOrder) => Promise<unknown>;
  resetTicketsOrder: () => void;
  resetStationDateSelection: () => void;
  setUnRefinedStations: React.Dispatch<React.SetStateAction<string[]>>;
  isTripSelection: boolean;
}

export const BookingContext = React.createContext<BookingContextType | null>(
  null,
);

export const BookingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [stations, setStations] = useState<string[]>([]);
  const [unRefinedStations, setUnRefinedStations] = useState<string[]>([]);
  const [tickets, setTickets] = useState<IRouteConnection[]>([]);
  const [ticket, setTicket] = useState<IRouteConnection>();
  const [passengers, setPassengers] = useState(1);
  const [ticketPrice, setTicketPrice] = useState<ITicketPrice>();
  const [ticketPrices, setTicketPrices] = useState<{
    [key: string]: ITicketPrice[];
  }>();
  const [ticketsOrder, setTicketsOrder] = useState<ITicketOrder[]>([]);
  const [isBooking, setIsBooking] = useState(false);
  const [isTripSelection, setIsTripSelection] = useState(true);
  const [hasConfirmedBooking, setHasConfirmedBooking] = useState(false);
  const [isLoadingRoutingDetails, setIsLoadingRoutingDetails] = useState(false);
  const [isLoadingPricingDetails, setIsLoadingPricingDetails] = useState(false);
  const [isSubmittingBookingDetails, setIsSubmittingBookingDetails] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStations, setSelectedStations] = useState({
    from: "",
    to: "",
  });

  const { shoppingCartBasket } = useContext(GlobalContext) as GlobalContextType;

  const handleTicketPriceSelection = (selected: ITicketPrice) => {
    setTicketPrice((prev) => ({ ...prev, ...selected }));
  };

  const handleTicketSelection = (selected: IRouteConnection) => {
    setTicket((prev) => ({ ...prev, ...selected }));
  };

  const resetTripSelectionBooking = () => {
    setIsBooking(false);
    setIsTripSelection(true);
  };

  const handleSetPassenger = (numb: number) => {
    setPassengers(numb);
    setIsSubmittingBookingDetails(false)
  };

  const handleBookingConfirmation = (isConfirmed: boolean) => {
    setHasConfirmedBooking(isConfirmed);
  };

  const resetStationDateSelection = () => {
    setSelectedDate("");
    setSelectedStations({
      from: "",
      to: "",
    });
  };

  const resetTicketsOrder = () => {
    setTickets([]);
    setTicketsOrder([]);
    setPassengers(1);
    setTicketPrice(undefined);
    setTicket(undefined);
    setIsBooking(false);
    setHasConfirmedBooking(false);
    setIsTripSelection(true);
  };

  const onChangedStation = (currentStation: { from: string; to: string }) => {
    if (
      // ticketsOrder.length &&
      currentStation.from !== selectedStations.from ||
      currentStation.to !== selectedStations.to
    ) {
      resetTicketsOrder();
      setIsLoadingRoutingDetails(false);
      setIsLoadingPricingDetails(false);
    }
  };

  const handleStationSelection = (selected: { [key: string]: string }) => {
    setSelectedStations((prev) => ({ ...prev, ...selected }));
    const currentStation = { ...selectedStations, ...selected };
    onChangedStation(currentStation);
    ApiInstance.set_stid_origin(unRefinedStations.indexOf(currentStation.from));
    ApiInstance.set_stid_destination(
      unRefinedStations.indexOf(currentStation.to),
    );
  };

  const handleDateSelection = (date: string) => {
    if (isNaN(new Date(date).getTime())) {
      toast.error("Invalid Date", { autoClose: 2000 });
      setSelectedDate("");
    } else {
      setSelectedDate(date);
      ApiInstance.set_stid_origin(
        unRefinedStations.indexOf(selectedStations.from),
      );
      ApiInstance.set_stid_destination(
        unRefinedStations.indexOf(selectedStations.to),
      );
      ApiInstance.set_input_date(moment(date).format("YYYY-MM-DD"));
    }
  };

  const onTripSubmissionTakingTooLong = () => {
    const timeout = setTimeout(() => {
      if (!tickets.length && ApiSessionInstance.getAwaitingApiResponse()) {
        ApiSessionInstance.clearAwaitingApiResponse();
        setIsLoadingRoutingDetails(false);
        setIsLoadingPricingDetails(false);
        toast.warning("Check your connection", {
          autoClose: 15000,
        });
      }
      clearTimeout(timeout);
    }, 15000);
  };

  const handleTripDetailSubmission = (event: any) => {
    if (shoppingCartBasket.length >= 3) {
      toast.dismiss();
      toast.warning("Ops! You can only purchase 3 tickets at a time", {
        autoClose: 15000,
      });
      return
    }
    ApiSessionInstance.clearFinishedCheckingAvailableRouting();
    setIsLoadingRoutingDetails(true);
    setTickets([]);
    const routingFinished = async (...props: any[]) => {
      ApiSessionInstance.onFinishedCheckingAvailableRouting();
      ApiSessionInstance.clearAwaitingApiResponse();
      console.log("...props", ...props);
      setIsLoadingRoutingDetails(false);
      setIsLoadingPricingDetails(true);
    };

    const routingOptions = (route: IRouteConnection, id: number) => {
      console.log("id", id, "route", route);
      if (!isLoadingRoutingDetails) setIsLoadingRoutingDetails(true);
      setTickets((prev) => [...prev, route]);
    };

    ApiSessionInstance.onAwaitingApiResponse();
    ApiInstance.start_routing(routingFinished, routingOptions);
    onTripSubmissionTakingTooLong();
  };

  const addTicketOrder = async (ticketOrder: ITicketOrder) => {
    return new Promise((res, reject) => {
      try {
        ApiInstance.add_ticket(ticketOrder, (data) => {
          ApiInstance.on_waiting = (waitingList: string[]) => {
            console.log("on_waiting", waitingList);
          };
          return res(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const getReservation = async (count: number) => {
    return new Promise((res, reject) => {
      try {
        ApiInstance.automatic_reservations((ok) => {
          if (ok) return res(ok);
          if (count === 1 && ticketsOrder.length === 1) {
            toast.error(
              "This train is fully booked, please choose another date/time or another class",
            );
          } else {
            toast.error(
              `This train can only take in ${count} more passenger(s), please choose another date/time or another class`,
            );
          }
          reject(
            "This train is fully booked, please choose another date/time or another class",
          );
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <BookingContext.Provider
      value={{
        stations,
        setStations,
        unRefinedStations,
        getReservation,
        selectedStations,
        selectedDate,
        handleDateSelection,
        handleStationSelection,
        handleTripDetailSubmission,
        isLoadingRoutingDetails,
        isLoadingPricingDetails,
        tickets,
        ticket,
        ticketPrice,
        ticketPrices,
        handleTicketPriceSelection,
        handleTicketSelection,
        passengers,
        handleSetPassenger,
        setTicketsOrder,
        hasConfirmedBooking,
        handleBookingConfirmation,
        isBooking,
        resetTripSelectionBooking,
        isSubmittingBookingDetails,
        setIsBooking,
        setIsTripSelection,
        setIsLoadingRoutingDetails,
        setIsLoadingPricingDetails,
        setTicketPrices,
        ticketsOrder,
        setIsSubmittingBookingDetails,
        addTicketOrder,
        resetTicketsOrder,
        resetStationDateSelection,
        setUnRefinedStations,
        isTripSelection,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
