import { Button, Col } from "react-bootstrap";
import Content from "./Content/Content";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

interface TextsComponentProps {
  isEmpty?: boolean;
  title: string;
  texts: string[];
  children?: React.ReactNode; // For reusability on buy page
}

const SmallScreenTextsComponent = ({
  children,
  isEmpty,
  title,
  texts,
}: TextsComponentProps) => {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  if (isMediumScreen) {
    const h1Class = isEmpty ? "text-start p-3" : "text-center";
    const startTextClass = "text-start";
    const generalClass = "mb-md-5 p-2 border border-primary";
    const colClass = isEmpty ? startTextClass : generalClass;
    const bg = "linear-gradient(#000,hsla(0,0%,100%,.091))";
    const colStyleAttribute = isEmpty ? {} : { background: bg };
    return (
      <Col
        style={colStyleAttribute}
        md={12}
        className={`m-auto rounded-4 d-none d-md-block ${colClass}`}
      >
        <h1 className={`text-capitalize text-primary ${h1Class}`}>{title}</h1>

        {isEmpty ? (
          <Button
            onClick={() => navigate("/")}
            variant="white"
            className="text-white position-absolute end-0 mx-3 top-0"
          >
            <i className="bi bi-x display-3" />
          </Button>
        ) : null}
        <Content
          title={title}
          texts={texts}
          isEmpty={isEmpty && isMediumScreen}
          children={children}
        />
      </Col>
    );
  }

  return (
    <Content
      title={title}
      texts={texts}
      isEmpty={isEmpty}
      children={children}
    />
  );
};

export default SmallScreenTextsComponent;
