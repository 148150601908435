import { Button, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import BuyingSuccessFeedback from "./BuyingSuccessFeedback/BuyingSuccessFeedback";
import React, { useContext, useEffect } from "react";
import BuyingSuccessSmallScreen from "./SmallScreen";
import ApiSessionInstance from "../../helpers/session_api";
import ApiInstance from "../../helpers/txticket_api";
import { toast } from "react-toastify";
import Layout from "../../Layout";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";

interface IBuyingSuccessProps {
  children?: React.ReactNode; // For reusability on buy page
  show?: boolean;
}

const BuyingSuccess = (props: IBuyingSuccessProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/buying-success" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isLargeScreenBelow = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const { setTicketList, setLastPurchaseTicketList, setPurchasedTickets } =
    useContext(GlobalContext) as GlobalContextType;

  useEffect(() => {
    if (ApiSessionInstance.getPaymentSuccessConfirmation()) {
      ApiInstance.get_last_purchase((purchase) => {
        console.log("get_last_purchase ticket =", purchase);
        if (purchase && Object.keys(purchase).length) {
          setLastPurchaseTicketList(purchase);
          setPurchasedTickets(purchase.ticketlist);
          setTicketList([purchase]);
        }
        const timeout = setTimeout(() => {
          ApiSessionInstance.resetPaymentSuccessConfirmation();
          // download ticket automatically upon showing the ticket
          // ApiSessionInstance.onDownloadTickets();
          // navigate(`/purchased-ticket/${purchase.purchaseid}`);
          navigate(`/purchase-history`);
          clearTimeout(timeout);
        }, 2000);
      });
    } else {
      toast.warning("you haven't made a new purchase");
      navigate("/purchase-history");
    }
  }, [navigate, setLastPurchaseTicketList, setPurchasedTickets, setTicketList]);

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="backgroundDropdown px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          show={props.show !== undefined ? props.show : !!matches}
          size="xl"
          centered
          style={{ marginTop: "30px" }}
        >
          <h1 className="text-capitalize text-primary text-center">
            {/* Shopping cart */}
          </h1>
          <Button
            onClick={() => navigate("/purchase-history")}
            variant="white"
            className="text-white position-absolute end-0 mx-3"
          >
            <i className="bi bi-x display-3" />
          </Button>
          <BuyingSuccessFeedback />
        </Modal>
      ) : (
        <BuyingSuccessSmallScreen />
      )}
    </Layout>
  );
};

export default BuyingSuccess;
