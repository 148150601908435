import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

interface BuyingSuccessFeedbackProps {
  children?: React.ReactNode; // For reusability on buy page
  hideOnSmallScreen?: boolean;
}

const BuyingSuccessFeedback = (props: BuyingSuccessFeedbackProps) => {
  return (
    <Fragment>
      {/* <Col md={9} className="fs-14 mt-3 mx-auto">
        <p
          className="text-white-50 text-center d-flex justify-content-between mx-auto"
          style={{ maxWidth: "300px" }}
        >
          <span className="text-white">Booking</span>
          <span className="text-white">&gt;</span>
          <span className="text-white">Shopping Cart</span>
          <span className="text-white">&gt;</span>
          <span className="text-white"> Buy </span>
          <span className="text-white">&gt;</span>
          <span className="text-white"> Ticket </span>
        </p>
      </Col> */}

      <Col
        style={{
          background: props.hideOnSmallScreen ? "transparent" : "transparent",
          height: "550px",
        }}
        md={9}
        lg={12}
        xl={9}
        className={`m-auto ${
          props.hideOnSmallScreen ? "" : "border border-primary mb-3"
        } rounded-4 p-2 px-md-3 py-md-5`}
      >
        <div
          className="mx-auto d-flex flex-column justify-content-center align-items-center"
          style={{ maxWidth: "420px", minHeight: "300px" }}
        >
          <i
            className="text-primary bi bi-check display-4"
            style={{
              fontSize: "300px",
              marginTop: "-90px",
              marginBottom: "-80px",
            }}
          />
          <div className="d-md-none d-block">
            <p className="text-capitalize text-white text-center pb-3 fs-27">
              <span className="text-primary display-4">Thank you</span> <br />
              <span className="fs-18" style={{ letterSpacing: "1.0px" }}>
                for your purchase.
              </span>
            </p>
          </div>
          <div className="d-md-block d-none">
            <p className="text-capitalize text-white text-center pb-3 fs-27">
              <span className="text-primary display-4">Thank you </span>
              <br />
              <span style={{ letterSpacing: "2.1px" }}>for your purchase.</span>
            </p>
          </div>
          <p
            className={`text-primary text-center ${
              props.hideOnSmallScreen ? "fs-13" : "fs-17"
            }`}
          >
            <Link to="/purchase-history">
              {" "}
              You will be forwarded to your purchased tickets
              <br /> within the next ten seconds, or click this link.
            </Link>
          </p>
        </div>
      </Col>
    </Fragment>
  );
};

export default BuyingSuccessFeedback;
