import { Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { IPurchasedTicket } from "../../../helpers/models/txticket_api.model";
import TicketItem from "./TicketItem/TicketItem";

interface TicketsProps {
  purchasedTicket: IPurchasedTicket[];
  maxHeight?: string;
}

const Ticket = ({ purchasedTicket, maxHeight }: TicketsProps) => {
  return (
    <SimpleBar style={{ maxHeight: maxHeight || "200px" }} className="">
      <Table className="align-middle table-nowrap mb-0 bg-transparent">
        <tbody className="text-white">
          {purchasedTicket.map((eachPurchasedTicket) => (
            <TicketItem
              key={eachPurchasedTicket.tno}
              purchasedTicket={eachPurchasedTicket}
            />
          ))}
        </tbody>
      </Table>
    </SimpleBar>
  );
};

export default Ticket;
