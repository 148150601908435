import React from "react";
import { Button, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

interface IGoButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  showGroupButtons?: boolean;
  disabledBackButton?: boolean;
  onClickBackButton?: (event: any) => void;
  disabledNextButton?: boolean;
  onClickNextButton?: (event: any) => void;
  onClick: (event: any) => void;
}

const GoButton = ({
  onClick,
  disabled,
  isLoading,
  disabledBackButton,
  onClickBackButton,
  disabledNextButton,
  onClickNextButton,
  showGroupButtons,
}: IGoButtonProps) => {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isSmallScreen = useMediaQuery({
    query:"(max-width: 380px)",
  });

  const btnStyle = isMediumScreen ? "btn-top-desktop" : "btn-top-mb";
  const mobileGoBtn = isMediumScreen ? "fs-130" : isSmallScreen?"fs-100": "fs-145";
  return (
    <Col md={9} className="m-auto" style={{ marginBottom: "none" }}>
      {!disabled && (
        <h4 className="fs-12 text-primary text-center my-2" style={{marginBottom: isMediumScreen ? "": "-10px"}}>
          Please click "GO" to continue
        </h4>
      )}
      <div className={`${btnStyle} btn-group align-items-center w-100`} style={{marginTop: isSmallScreen? "-10px" : ""}}>
        {showGroupButtons && (
          <Button
            disabled={disabledBackButton}
            variant="outline"
            className="btn btn-outline-primary btn-sm rounded-pill btn-icons-group "
            onClick={onClickBackButton}
            style={{ fontWeight: 900, maxWidth: "70px" }}
          >
            <i className="bx bxs-chevron-left"></i>
          </Button>
        )}
        <Button
          onClick={onClick}
          disabled={isLoading || disabled}
          className={`${mobileGoBtn} btn-go  text-primary btn-topbar btn-ghost-dark arrow-none ${
            isLoading ? "loading" : ""
          }`}
        >
          GO
        </Button>
        {showGroupButtons && (
          <Button
            disabled={disabledNextButton}
            variant="outline"
            onClick={onClickNextButton}
            className="btn btn-outline-primary btn-sm rounded-pill btn-icons-group"
            style={{ fontWeight: 900, maxWidth: "70px" }}
          >
            <i className="bx bxs-chevron-right"></i>
          </Button>
        )}
      </div>
    </Col>
  );
};

export default GoButton;
