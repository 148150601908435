import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import qrpics from "../../../assets/images/ticket/qrpics.png";

interface ContentProps {
  isEmpty?: boolean;
  isLargeScreen?: boolean;
  children?: React.ReactNode; // For reusability on buy page
}

const Content = ({ isEmpty, isLargeScreen }: ContentProps) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      {/* <div className="text-light-emphasis d-flex flex-column justify-content-center position-relative"> */}
      <div className="position-relative w-100 d-flex d-sm-none text-light-emphasis justify-content-center">
        <img
          onClick={() => navigate("/purchased-ticket/1")}
          onDoubleClick={() => navigate("/purchased-ticket/1")}
          style={{ maxWidth: "85%" }}
          className="mx-auto w-100 border border-3 border-danger"
          src={qrpics}
          alt="qrpics"
        />
      </div>
      <Row className="d-none d-sm-flex text-light-emphasis">
        <Col sm={1} className="position-relative">
          <p className="opacity-0">screen</p>
        </Col>
        <Col sm={10}>
          <img
            className="mx-auto w-100 border border-3 border-danger"
            src={qrpics}
            alt="qrpics"
            onClick={() => navigate("/purchased-ticket/1")}
            onDoubleClick={() => navigate("/purchased-ticket/1")}
          />
        </Col>
        <Col sm={1} className="position-relative">
          <p className="opacity-0">screen</p>
        </Col>
      </Row>
      <p className="text-danger fs-20 text-center my-2">
        Click QR-code to unlock
      </p>
    </Fragment>
  );
};

export default Content;
