import { Col } from "react-bootstrap";
import BuyingSuccessFeedback from "./BuyingSuccessFeedback/BuyingSuccessFeedback";
import { Fragment } from "react";

const SmallScreenBuyingSuccess = () => {
  return (
    <Fragment>
      <Col
        style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
        md={10}
        className="m-auto border border-primary rounded-4 p-2 text-center mb-md-5 d-none d-md-block"
      >
        <h1 className="text-capitalize text-primary text-center">
          Shopping cart
        </h1>
        <BuyingSuccessFeedback hideOnSmallScreen />
      </Col>
      <div className="d-md-none d-block">
        <BuyingSuccessFeedback hideOnSmallScreen />
      </div>
    </Fragment>
  );
};

export default SmallScreenBuyingSuccess;
