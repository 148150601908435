import { Button, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import BuyingProcessedLoader from "./BuyingProcessedLoader/BuyingProcessedLoader";
import React, { useEffect } from "react";
import BuyingProcessedLoadingSmallScreen from "./SmallScreen";
import ApiSessionInstance from "../../helpers/session_api";
// import ApiInstance from "../../helpers/txticket_api";
import { toast } from "react-toastify";
import Layout from "../../Layout";
import ApiInstance from "../../helpers/txticket_api";
import DynamicStylerInstance, {
  modalStyle,
} from "../../helpers/dynamic_styler_helper";

interface IBuyingProcessedLoadingProps {
  children?: React.ReactNode; // For reusability on buy page
  show?: boolean;
}

const BuyingProcessedLoading = (props: IBuyingProcessedLoadingProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/buying-processed-loading" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isLargeScreenBelow = useMediaQuery({
    query: "(min-width: 992px)",
  });

  useEffect(() => {
    if (!ApiSessionInstance.getBuyingProcessedLoadingConfirmed()) {
      ApiSessionInstance.resetBuyingProcessedLoadingConfirmed();
      const data = ApiSessionInstance.getPaymentSuccessConfirmation();
      if (data) {
        ApiInstance.confirm_payment(
          data.pgwname,
          data.response.merchantTransactionRef,
          data.response.transactionRef,
          data,
          () => {
            DynamicStylerInstance.applyStyles(modalStyle);
            ApiSessionInstance.resetStartPayment();
            ApiSessionInstance.clearAbruptlyCancelledPurchase();
            ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
            console.log("this is the confirmation of payment:");
            ApiInstance.clear_shopping_basket();
            // navigate("/buying-processed-loading");
            navigate("/buying-success");
          },
        );
        // const { pmtref, response, pgwname, pmtauth } = data;
        // ApiInstance.confirm_payment(pgwname, pmtref, pmtauth, response, () => {
        // const timeout = setTimeout(() => {
        //   navigate("/buying-success");
        //   clearTimeout(timeout);
        // }, 5000);
        // });
      } else {
        toast.warning("There is no ongoing payment confirmation");
        navigate("/shopping-cart");
      }
    }
  }, [navigate]);

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="backgroundDropdown px-2 px-lg-6 py-4 rounded-4"
          backdropClassName="bg-transparent"
          show={props.show !== undefined ? props.show : !!matches}
          size="xl"
          centered
          style={{ marginTop: "40px" }}
        >
          <h1 className="text-capitalize text-primary text-center">
            {/* <span style={{ color: "white" }}>PLEASE</span> WAIT... */}
          </h1>
          <Button
            onClick={() => navigate("/purchase-history")}
            variant="white"
            className="text-white position-absolute end-0 mx-3"
          >
            <i className="bi bi-x display-3" />
          </Button>
          <BuyingProcessedLoader />
        </Modal>
      ) : (
        <BuyingProcessedLoadingSmallScreen />
      )}
    </Layout>
  );
};

export default BuyingProcessedLoading;
