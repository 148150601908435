// import { useEffect } from "react";
// import ApiSessionInstance from "../../helpers/session_api";
// import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
// import DynamicStylerInstance, { modalStyle } from "../../helpers/dynamic_styler_helper";

const Buying = () => {
  // const navigate = useNavigate();
  // const currentLocation = useLocation();
  // const someRoutes = [{ path: "/buying" }];
  // const matches = matchRoutes(someRoutes, currentLocation);

  // useEffect(() => {
  //   if (
  //     !document.querySelector("#icadpay-modal") &&
  //     ApiSessionInstance.getShowPaymentGateway()
  //   ) {
  //     ApiSessionInstance.setAbruptlyCancelledPurchase();
  //     ApiSessionInstance.resetShowPaymentGateway();
  //     navigate("/shopping-cart");
  //   }
  // }, [navigate]);

  // useEffect(() => {
  //   if (
  //     matches &&
  //     currentLocation.state &&
  //     currentLocation.state.method === "buying" &&
  //     ApiSessionInstance.getShowPaymentGateway()
  //   ) {
  //     window.addEventListener("popstate", function (event) {
  //       ApiSessionInstance.setAbruptlyCancelledPurchase();
  //       ApiSessionInstance.resetShowPaymentGateway();
  //       DynamicStylerInstance.applyStyles(modalStyle);
  //       // this.window.location.reload();
  //     });
  //   }

  //   if (!ApiSessionInstance.getShowPaymentGateway()) {
  //     navigate("/shopping-cart");
  //   }
  // }, [currentLocation.state, matches, navigate]);

  return <div />;
};

export default Buying;
