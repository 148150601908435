interface CSSStyles {
  [selector: string]: Partial<CSSStyleDeclaration>;
}

class DynamicStyler {
  private styleElement: HTMLStyleElement;

  constructor() {
    this.styleElement = document.createElement("style");
    document.head.appendChild(this.styleElement);
  }

  public applyStyles(styles: CSSStyles): void {
    const styleContent = this.convertStylesToString(styles);
    this.styleElement.textContent = styleContent;
  }

  private convertStylesToString(styles: CSSStyles): string {
    return Object.entries(styles)
      .map(([selector, rules]) => {
        const rulesString = Object.entries(rules)
          .map(([property, value]) => {
            // Ensure value is defined and not an empty string
            if (value) {
              // Convert camelCase property to kebab-case
              const kebabCaseProperty = property.replace(
                /[A-Z]/g,
                (match) => `-${match.toLowerCase()}`
              );
              return `${kebabCaseProperty}: ${value};`;
            }
            return "";
          })
          .filter((rule) => rule)
          .join(" ");
        return `${selector} { ${rulesString} }`;
      })
      .join(" ");
  }
}

const DynamicStylerInstance = new DynamicStyler();

export default DynamicStylerInstance;

// Fix: modal backdrop stuck on navigation making modal content non-interactive
export const modalStyle: CSSStyles = {
  ".modal": {
    zIndex: "1500!important",
  },
};

// Usage example:

// const styles: CSSStyles = {
//   div: {
//     color: "blue",
//     display: "none",
//     backgroundColor: "lightgrey",
//   },
//   ".highlight": {
//     fontWeight: "bold",
//     textDecoration: "underline",
//   },
// };

// DynamicStylerInstance.applyStyles(styles);
