import { Button, Table } from "react-bootstrap";

const TicketHeading = () => {
  return (
    <Table className="align-middle table-nowrap mb-0">
      <thead>
        <tr className="fs-10" style={{ marginTop: "-10px" }}>
          <th
            className="bg-transparent text-dark border-0 fs-11 p-0"
            scope="col"
            style={{ width: "46px", fontWeight: "800" }}
          >
            Qty
          </th>
          <th
            className="bg-transparent text-dark border-0 p-0 text-start fs-11"
            scope="col"
            style={{ fontWeight: "800" }}
          >
            Booking
          </th>

          {/* <th
            className="bg-transparent text-dark border-0 text-start"
            scope="col"
            style={{ width: "46px" }}
          >
            Email
            <i className="bi bi-check text-primary" />
          </th>
          <th
            className="bg-transparent text-dark border-0 text-start"
            scope="col"
            style={{ width: "46px" }}
          >
            NIN
            <i className="bi bi-check text-primary" />
          </th> */}
        </tr>
      </thead>
      <tbody className="text-white position-absolute opacity-0">
        <tr>
          <td className="bg-dark text-white border-0 fs-13 pt-0">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-0 fs-14">2</p>
              <Button className="btn-sm bg-transparent btn-ghost-primary text-primary">
                <i
                  style={{ transform: "scale(1.5)" }}
                  className="bx bxs-trash-alt"
                />
              </Button>
            </div>
          </td>
          <td className="bg-dark text-white border-0 fs-13">
            <p className="fs-14 mb-2 text-light-emphasis">
              {/* {selectedStations.from} -&gt; {selectedStations.to} */}
              Aba -&gt; Lagos
            </p>
            <p>
              Tuesday, May 7, 2024, Train No. 264, Free Seating, Price ₦ 800.00
              Tuesday, May 7, 2024, Train No. 264, Free Seating, Price ₦ 800.00
              Tuesday, May 7, 2024, Train No. 264, Free Seating, Price ₦ 800.00
            </p>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TicketHeading;
