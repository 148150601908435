import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

const InformationNavigation = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/information" }];
  const matches = matchRoutes(someRoutes, currentLocation);

  const handleClick = () => {
    navigate("/information");
    if (onClick) onClick();
  };

  return (
    <React.Fragment>
      <div className="ms-1 header-item-responsive d-sm-flex">
        <button
          type="button"
          className={`btn btn-ghost-primary ${className ? className : ""}`}
          onClick={handleClick}
        >
          <span
            className={`fw-bolder user-name-text fs-16 text-capitalize text-${
              matches ? "primary" : "white"
            }`}
          >
            Information
          </span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default InformationNavigation;
