import React from "react";
import { useMediaQuery } from "react-responsive";
import SelectLocationDropdown from "./SelectLocationDropdown";
import { Col } from "react-bootstrap";
import SelectDateDropdown from "./SelectDateDropdown";
import GoButton from "../../../component/GoButton/GoButton";

interface ITripSelectionFormProps {
  stations: string[];
  selectedDate: string;
  // isTripFormValid: boolean;
  isLoadingBookingDetails: boolean;
  selectedStations: { from: string; to: string };
  handleDateSelection: (event: any) => void;
  handleStationSelection: (selected: { [key: string]: string }) => void;
  handleTripDetailSubmission: (event: any) => void;
  isBooking: boolean;
  onClickNextButton?: ((event: any) => void) | undefined;
}

const TripSelectionForm = ({
  stations,
  selectedDate,
  selectedStations,
  handleDateSelection,
  handleStationSelection,
  handleTripDetailSubmission,
  isLoadingBookingDetails,
  isBooking,
  onClickNextButton,
}: ITripSelectionFormProps) => {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const pleaseSelete = isMediumScreen ? "fm-3" : "fm-2";

  return (
    <div>
      <p className={`${pleaseSelete} text-white text-center`} style={{ marginBottom: isMediumScreen? "":"-5px"}}>
        PLEASE SELECT YOUR TRIP
      </p>
      <Col md={10} sm={6} className="m-auto">
        <SelectLocationDropdown
          selectedStations={selectedStations}
          handleStationSelection={handleStationSelection}
          stations={stations}
          text="FROM"
        />
        <SelectLocationDropdown
          selectedStations={selectedStations}
          handleStationSelection={handleStationSelection}
          stations={stations}
          text="TO"
        />
        {!stations.length ? null : (
          <SelectDateDropdown
            handleDateSelection={handleDateSelection}
            selectedDate={selectedDate}
          />
        )}
      </Col>
      <GoButton
        disabled={
          !selectedStations.from || !selectedStations.to || !selectedDate
        }
        isLoading={isLoadingBookingDetails}
        onClick={handleTripDetailSubmission}
        showGroupButtons
        disabledBackButton
        disabledNextButton={isBooking || isLoadingBookingDetails}
        onClickNextButton={onClickNextButton}
      />
    </div>
  );
};

export default TripSelectionForm;
