import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import {
  BookingContext,
  BookingContextType,
} from "../../../context/BookingContext";
import { toast } from "react-toastify";
import ApiSessionInstance from "../../../helpers/session_api";

const HomeNavigation = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const { setIsBooking, setIsTripSelection } = React.useContext(
    BookingContext,
  ) as BookingContextType;

  const resetBooking = () => {
    toast.dismiss();
    setIsBooking(false);
    setIsTripSelection(true);
    ApiSessionInstance.clearAwaitingApiResponse();
    ApiSessionInstance.clearConfirmBooking();
  };

  const handleClick = () => {
    navigate("/");
    if (onClick) onClick();
    resetBooking();
  };

  return (
    <React.Fragment>
      <div className="ms-1 header-item-responsive d-sm-flex">
        <button
          type="button"
          className={`btn btn-ghost-primary ${className ? className : ""}`}
          onClick={handleClick}
        >
          <span
            className={`fw-bolder user-name-text fs-17 text-capitalize text-${
              matches ? "primary" : "white"
            }`}
            
          >
            Home
          </span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default HomeNavigation;
