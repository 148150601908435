import { Col } from "react-bootstrap";
import Orders from "./Orders/Orders";
import { Fragment } from "react";

const SmallScreenPurchaseHistory = () => {
  return (
    <Fragment>
      <Col
        style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
        md={10}
        className="m-auto border border-primary rounded-4 p-2 text-center mb-md-5 d-none d-md-block"
      >
        <h1 className="text-capitalize text-primary text-center">
          Purchase History
        </h1>
        <Orders />
      </Col>

      <div className="d-md-none d-block">
        <Orders />
      </div>
    </Fragment>
  );
};

export default SmallScreenPurchaseHistory;
