import { Fragment } from "react";
import { Modal, Button, ModalHeader, ModalBody } from "react-bootstrap";

interface IRemoveItemPromptProps {
  onClose?: (() => void) | undefined;
  isOpen: boolean;
  title: string;
  text: string;
  onAcceptPrompt?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

// NOTE: This component mirror the buy page.
// It's essentially retrying the unintentional payment cancellation.
export const RemoveItemPrompt = ({
  onClose,
  isOpen,
  title,
  text,
  onAcceptPrompt,
}: IRemoveItemPromptProps) => {
  return (
    <Fragment>
      <Modal
        show={isOpen}
        onHide={onClose}
        id="removeCartModal"
        className="fade zoomIn "
        dialogClassName="modal-dialog-centered"
      >
        {/* <ModalHeader closeButton className="bg-dark "></ModalHeader> */}
        <ModalBody className="p-md-5 bg-dark border border-primary rounded-3">
          <div className="text-center">
            <div className="text-danger">
              {/* <i className="bi bi-cart-check-fill display-5 text-success"></i> */}
            </div>
            <div className="mt-4 text-primary">
              <h4 className="text-primary fs-30">{title} ?</h4>
              <p className="text-muted mx-4 mb-0 text-primary">{text} ?</p>
            </div>
          </div>
          <div className="d-flex gap-4 justify-content-center mt-4 mb-2">
            <Button
              type="button"
              variant="transperent"
              className="btn w-sm fs-18"
              onClick={onAcceptPrompt}
              style={{ color: "red", fontWeight: 600 }}
            >
              Delete
            </Button>

            <Button
              type="button"
              variant="transperent"
              className="btn w-sm btn-hover text-primary fs-18"
              id="delete-record"
              onClick={onClose}
              style={{ fontWeight: 600 }}
            >
              Keep
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
