import { Button, Col } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";

interface ContentProps {
  isEmpty?: boolean;
  texts: string[];
  title: string;
  isLargeScreen?: boolean;
  children?: React.ReactNode; // For reusability on buy page
}

const Content = ({ isEmpty, texts, title, isLargeScreen }: ContentProps) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Col
        style={{
          background: "transparent",
          minHeight: isLargeScreen ? "470px" : "auto",
        }}
        md={12}
        lg={12}
        xl={9}
        className={`m-auto rounded-4 p-2 px-md-3 py-md-3 ${
          isEmpty ? "" : "mb-5"
        }
        ${isLargeScreen ? "border border-primary mb-5" : ""}`}
      >
        <div className="d-md-none d-block">
          <h1
            className={`text-capitalize text-primary ${
              isEmpty ? "text-start py-3" : "text-center"
            } pb-3`}
          >
            {title}
          </h1>
          {isEmpty ? (
            <Button
              onClick={() => navigate("/")}
              variant="white"
              className="text-white position-absolute end-0 mx-3 top-0 mt-2"
            >
              <i className="bi bi-x display-3" />
            </Button>
          ) : null}
        </div>
        <div className="text-light-emphasis">
          {texts.map((text) => (
            <p key={text}>{text}</p>
          ))}
        </div>
      </Col>
    </Fragment>
  );
};

export default Content;
