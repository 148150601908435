import { IBuyerDetailsDto, IUserDto } from "./models/auth.model";
import {
  ILastPurchaseResponse,
  IPaymentSuccessConfirmationDto,
  IPurchasedTicket,
  IStartPaymentResponse,
} from "./models/txticket_api.model";

class SessionApi {
  // For ticket Booking
  onFinishedCheckingAvailableRouting() {
    return sessionStorage.setItem(
      "FinishedCheckingAvailableRouting",
      "mounted"
    );
  }

  getFinishedCheckingAvailableRouting() {
    return sessionStorage.getItem("FinishedCheckingAvailableRouting");
  }

  clearFinishedCheckingAvailableRouting() {
    return sessionStorage.removeItem("FinishedCheckingAvailableRouting");
  }

  // For ticket Booking and possibly any other needs
  onAwaitingApiResponse() {
    return sessionStorage.setItem("AwaitingApiResponse", "mounted");
  }

  getAwaitingApiResponse() {
    return sessionStorage.getItem("AwaitingApiResponse");
  }

  clearAwaitingApiResponse() {
    return sessionStorage.removeItem("AwaitingApiResponse");
  }

  onPurchaseWithoutRegisteringMounted() {
    return sessionStorage.setItem(
      "PurchaseWithoutRegisteringMounted",
      "mounted"
    );
  }

  getPurchaseWithoutRegisteringMounted() {
    return sessionStorage.getItem("PurchaseWithoutRegisteringMounted");
  }

  removePurchaseWithoutRegisteringMounted() {
    return sessionStorage.removeItem("PurchaseWithoutRegisteringMounted");
  }

  // For Continue Purchase
  setAbruptlyCancelledPurchase() {
    return sessionStorage.setItem("abruptlyCancelledPurchase", "mounted");
  }

  getAbruptlyCancelledPurchase() {
    return sessionStorage.getItem("abruptlyCancelledPurchase");
  }

  clearAbruptlyCancelledPurchase() {
    return sessionStorage.removeItem("abruptlyCancelledPurchase");
  }

  // For Continue Purchase
  setCancelledPurchaseContinuationPrompt() {
    return sessionStorage.setItem(
      "CancelledPurchaseContinuationPrompt",
      "mounted"
    );
  }

  getCancelledPurchaseContinuationPrompt() {
    return sessionStorage.getItem("CancelledPurchaseContinuationPrompt");
  }

  clearCancelledPurchaseContinuationPrompt() {
    return sessionStorage.removeItem("CancelledPurchaseContinuationPrompt");
  }

  getShowPaymentGateway() {
    return sessionStorage.getItem("ShowPaymentGateway");
  }

  onShowPaymentGateway() {
    return sessionStorage.setItem("ShowPaymentGateway", "mounted");
  }

  resetShowPaymentGateway() {
    return sessionStorage.removeItem("ShowPaymentGateway");
  }

  getBuyingProcessedLoadingConfirmed() {
    return sessionStorage.getItem("BuyingProcessedLoadingConfirmed");
  }

  onBuyingProcessedLoadingConfirmed() {
    return sessionStorage.setItem("BuyingProcessedLoadingConfirmed", "mounted");
  }

  resetBuyingProcessedLoadingConfirmed() {
    return sessionStorage.removeItem("BuyingProcessedLoadingConfirmed");
  }

  getStartPayment() {
    const foundData = sessionStorage.getItem("StartPayment");
    if (foundData) return JSON.parse(foundData) as IStartPaymentResponse;
    return undefined;
  }

  onStartPayment(data: IStartPaymentResponse) {
    return sessionStorage.setItem("StartPayment", JSON.stringify(data));
  }

  resetStartPayment() {
    return sessionStorage.removeItem("StartPayment");
  }

  getPaymentSuccessConfirmation() {
    const foundData = sessionStorage.getItem("PaymentSuccessConfirmation");
    if (foundData)
      return JSON.parse(foundData) as IPaymentSuccessConfirmationDto;
    return undefined;
  }

  onPaymentSuccessConfirmation(data: IPaymentSuccessConfirmationDto) {
    return sessionStorage.setItem(
      "PaymentSuccessConfirmation",
      JSON.stringify(data)
    );
  }

  resetPaymentSuccessConfirmation() {
    return sessionStorage.removeItem("PaymentSuccessConfirmation");
  }

  getTicketList() {
    const foundData = sessionStorage.getItem("TicketList");
    if (foundData) return JSON.parse(foundData) as ILastPurchaseResponse[];
    return undefined;
  }

  setTicketList(data: ILastPurchaseResponse[]) {
    if (Object.keys(data[0] || {}).length) {
      return sessionStorage.setItem("TicketList", JSON.stringify(data));
    }
  }

  clearTicketList() {
    return sessionStorage.removeItem("TicketList");
  }

  getPurchasedTickets() {
    const foundData = sessionStorage.getItem("PurchasedTickets");
    if (foundData) return JSON.parse(foundData) as IPurchasedTicket[];
    return undefined;
  }

  setPurchasedTickets(data: IPurchasedTicket[]) {
    return sessionStorage.setItem("PurchasedTickets", JSON.stringify(data));
  }

  clearPurchasedTickets() {
    return sessionStorage.removeItem("PurchasedTickets");
  }

  getDownloadTickets() {
    return sessionStorage.getItem("DownloadTickets");
  }

  onDownloadTickets() {
    return sessionStorage.setItem("DownloadTickets", "mounted");
  }

  clearDownloadTickets() {
    return sessionStorage.removeItem("DownloadTickets");
  }

  setBuyerDetails(data: IBuyerDetailsDto) {
    return sessionStorage.setItem("BuyerDetails", JSON.stringify(data));
  }

  getBuyerDetails() {
    const foundData = sessionStorage.getItem("BuyerDetails");
    if (foundData) return JSON.parse(foundData) as IBuyerDetailsDto;
    return undefined;
  }

  removeBuyerDetails() {
    return sessionStorage.removeItem("BuyerDetails");
  }

  setUser(data: IUserDto) {
    return sessionStorage.setItem("User", JSON.stringify(data));
  }

  getUser() {
    const foundData = sessionStorage.getItem("User");
    if (foundData) return JSON.parse(foundData) as IUserDto;
    return undefined;
  }

  removeUser() {
    return sessionStorage.removeItem("User");
  }

  getConfirmBooking() {
    return sessionStorage.getItem("ConfirmBooking");
  }

  onConfirmBooking() {
    return sessionStorage.setItem("ConfirmBooking", "mounted");
  }

  clearConfirmBooking() {
    return sessionStorage.removeItem("ConfirmBooking");
  }

  getConfirmBuyerDetails() {
    return sessionStorage.getItem("ConfirmBuyerDetails");
  }

  onConfirmBuyerDetails() {
    return sessionStorage.setItem("ConfirmBuyerDetails", "mounted");
  }

  clearConfirmBuyerDetails() {
    return sessionStorage.removeItem("ConfirmBuyerDetails");
  }
}

const ApiSessionInstance = new SessionApi();

export default ApiSessionInstance;
