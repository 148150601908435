import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../context/GlobalContext";

const ShoppingCartNavigation = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/shopping-cart" }];
  const matches = matchRoutes(someRoutes, currentLocation);

  const { shoppingCartBasket } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;

  const handleClick = () => {
    navigate("/shopping-cart");
    if (onClick) onClick();
  };

  return (
    <React.Fragment>
      <div className="ms-1 header-item-responsive d-sm-flex">
        <button
          type="button"
          id="shopping-cart-nav-item"
          className={`btn btn-ghost-primary ${className ? className : ""}`}
          onClick={handleClick}
        >
          <span
            className={`fw-bolder user-name-text fs-16 text-capitalize text-${
              matches ? "primary" : "white"
            }`}
          >
            Shopping Cart
          </span>

          {shoppingCartBasket.length < 1 ? null : (
            <span className="ms-2 d-none d-md-inline-block">
              <sup
                className={`badge badge-primary position-absolute end-0 ${
                  shoppingCartBasket.length > 9 ? "px-1" : ""
                }`} style={{ border : '1px solid #00f600', }}
              >
                {shoppingCartBasket.length}
              </sup>
              {/* <i className="bi bi-cart-check-fill text-primary"></i> */}
            </span>
          )}
        </button>
      </div>
    </React.Fragment>
  );
};

export default ShoppingCartNavigation;
