import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, ModalHeader, ModalBody } from "react-bootstrap";
import ApiInstance from "../../helpers/txticket_api";
import { toast } from "react-toastify";
import ApiSessionInstance from "../../helpers/session_api";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { IStartPaymentResponse } from "../../helpers/models/txticket_api.model";
import { IIcadPayPaymentResponse } from "../../helpers/models/payment_gateway.model";

// NOTE: This component mirror the buy page.
// It's essentially retrying the unintentional payment cancellation.
export const ContinuePurchase = () => {
  const navigate = useNavigate();
  const [lastPaymentReference, setLastPaymentReference] = useState<{
    state: "PAYING";
    pmtref: string;
  }>();
  // const [state, setState] = useState<IStartPaymentResponse>();
  const [narration] = useState("NRC - ticket purchase");
  const [selectedPaymentProvider] = useState("icadpay");
  const { buyerDetails, user } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const getPaymentRefProviderName = () => {
    if (ApiSessionInstance.getStartPayment()) {
      return ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
    }
    return null;
  };

  const onPaymentSuccess = (response: IIcadPayPaymentResponse) => {
    console.log("onPaymentSuccess response", response);
    navigate("/buying-processed-loading");
    // const dt = getPaymentRefProviderName();
    // ApiSessionInstance.onPaymentSuccessConfirmation({
    //   response: response.responseData,
    //   pgwname: dt ? dt.pgwname : selectedPaymentProvider,
    //   pmtref: response.responseData.merchantTransactionRef,
    //   pmtauth: response.responseData.transactionRef,
    // });

    // ApiInstance.confirm_payment(
    //   dt ? dt.pgwname : selectedPaymentProvider,
    //   response.responseData.merchantTransactionRef,
    //   response.responseData.transactionRef,
    //   response.responseData,
    //   () => {
    //     ApiSessionInstance.resetStartPayment();
    //     ApiSessionInstance.clearAbruptlyCancelledPurchase();
    //     ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    //     console.log("this is the confirmation of payment:");
    //     ApiInstance.clear_shopping_basket();
    //     navigate("/buying-processed-loading");
    //   }
    // );
  };

  const onPaymentError = (response: any) => {
    ApiSessionInstance.clearAbruptlyCancelledPurchase();
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    console.log("onPaymentError response", response);
    toast.error("ticket purchase failed..");
    // if (state) {
    if (ApiSessionInstance.getStartPayment()) {
      // ApiInstance.cancel_payment(selectedPaymentProvider, state["pmtref"]);
      const dt = ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
      ApiInstance.cancel_payment(dt.pgwname, dt["pmtref"]);
      ApiSessionInstance.resetStartPayment();
    }
  };

  const onClosePayment = () => {
    // const pmtref = ApiInstance.get_last_pmtref();
    // console.log('onClosePayment pmtref', pmtref)
    toast.success("payment window closed");
    // if (state) {
    if (ApiSessionInstance.getStartPayment()) {
      // ApiInstance.cancel_payment(selectedPaymentProvider, state["pmtref"]);
      const dt = ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
      ApiInstance.cancel_payment(dt.pgwname, dt["pmtref"]);
      setLastPaymentReference(undefined);
      ApiSessionInstance.clearAbruptlyCancelledPurchase();
      ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
      ApiSessionInstance.resetStartPayment();
    }
    navigate("/shopping-cart");
    // window.location.reload();
  };

  const onStartPurchase = () => {
    if (!buyerDetails) return;
    const totalShoppingCartBasketPrice =
      ApiInstance.get_shopping_basket_amount();
    const email = user ? user.email : buyerDetails.email;
    const firstName = user ? user.firstName : buyerDetails.firstName;
    const lastName = user ? user.lastName : buyerDetails.lastName;
    const telephone = user ? user.telephone : buyerDetails.telephone;
    ApiSessionInstance.clearAbruptlyCancelledPurchase();
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    setLastPaymentReference(undefined);
    ApiInstance.start_payment(
      selectedPaymentProvider,
      email,
      `${narration} by ${firstName} ${lastName}`,
      (data) => {
        ApiSessionInstance.clearAbruptlyCancelledPurchase();
        ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
        navigate("/buying", { state: { method: "buying", data } });
        // setState(data);
        ApiSessionInstance.onStartPayment(data);
        const payload = {
          // key: "live_NDNlMGM5ZjJlYzliZTBmYjNiMjgzOGFlNzkyZGQ4MTBjZDFmMjQ2YjYzZWRhMmNkYjJhOGMwYWNjZTA3MDJmNA", // this is not a demo key.
          // key: "test_MDE0OTY1Y2NjNDI0MjIyZjY1ZWYwOWQ1YzkyMmJjZTZkYzlhZDBiZDVkOTg5ZDBmZjllYTMyMzVjNTI4MmJmMQ", // this is a demo key.
          // key: 'test_YzA1MmNmYzE0OTc1Y2QyM2ViNWUwNTIxOGMzZjA2MjI5N2M4ZDU3YmY5ZDg1ZmU1OWIwNWE1NDU0YjkzYTZkOQ', // this is a demo key.
          key: 'test_NjA0N2QzZjg1NjNjOGIwYTNmYzIwZjNmZTY1MGE0NDZmYjk1OTgxNWMzODdiOTFiYzVlNzM5YWM4MGU5NDM0Nw',
          email: buyerDetails.email, // customer email
          amount: totalShoppingCartBasketPrice / 100, // amount to be processed
          currency: "NGN", // currency
          first_name: firstName,
          last_name: lastName,
          phone_number: telephone, // customer's phone number (optional)
          customerId: email,
          ref: data["pmtref"],
          // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
          narration: `${narration} by ${firstName} ${lastName}`,
          isBill: true,
          service_bill: "",
          callback_url: "https://nrc-icad.txware.com/", // specified redirect URL (potional)
          // callback_url: '', // specified redirect URL (potional)
          callback: (response: any) => {
            // console.log('cont. selectedPaymentProvider - callback', selectedPaymentProvider)
            // console.log('cont. ApiSessionInstance - callback', ApiSessionInstance.getStartPayment())
            if (
              response.requestSuccessful === true &&
              response.responseCode === "00" &&
              response.responseData &&
              response.responseData.responseCode === "00"
            ) {
              const dt = getPaymentRefProviderName();
              ApiSessionInstance.resetStartPayment();
              ApiSessionInstance.clearAbruptlyCancelledPurchase();
              ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
              ApiSessionInstance.onPaymentSuccessConfirmation({
                response: response.responseData,
                pgwname: dt ? dt.pgwname : selectedPaymentProvider,
                pmtref: response.responseData.merchantTransactionRef,
                pmtauth: response.responseData.transactionRef,
              });
              // console.log('onPaymentSuccessConfirmation', response);
            }
            // console.log(response);
          },
          onSuccess: onPaymentSuccess,
          onError: onPaymentError,
          onClose: onClosePayment,
        };
        async function iCadPay() {
          if (window.IcadPay) {
            const handler = await window.IcadPay.setup(payload);
            ApiSessionInstance.onShowPaymentGateway();
            console.log(handler);
          }
        }
        iCadPay();
      }
    );
  };

  useEffect(() => {
    if (ApiSessionInstance.getAbruptlyCancelledPurchase()) {
      ApiInstance.on_payment_changed = (data: any) => {
        console.log("on_payment_changed", data);
        ApiSessionInstance.clearAbruptlyCancelledPurchase();
        ApiSessionInstance.setCancelledPurchaseContinuationPrompt();
        setLastPaymentReference(data);
      };
    }
  }, []);

  return (
    <Fragment>
      <Modal
        show={
          !!lastPaymentReference && !ApiSessionInstance.getShowPaymentGateway()
        }
        onHide={onClosePayment}
        id="removeCartModal"
        className="fade zoomIn"
        dialogClassName="modal-dialog-centered"
      >
        <ModalHeader closeButton></ModalHeader>
        <ModalBody className="p-md-5">
          <div className="text-center">
            <div className="text-danger">
              <i className="bi bi-cart-check-fill display-5 text-success"></i>
            </div>
            <div className="mt-4">
              <h4>Continue Payment ?</h4>
              <p className="text-muted mx-4 mb-0">
                Would you like to continue your payment ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button
              type="button"
              variant="primary"
              className="btn w-sm"
              onClick={onStartPurchase}
            >
              Continue Payment
            </Button>
            <Button
              type="button"
              variant="danger"
              className="btn w-sm btn-hover"
              id="delete-record"
              onClick={onClosePayment}
            >
              No, Cancel Payment!
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
